import React from "react";

function WhyChooseUs() {
  return (
    <>
      <section
        className="why-choose sec-mar wow animate fadeIn"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img
                    src={process.env.PUBLIC_URL + "/img/why-1.jpg"}
                    alt="why-choose images"
                  />
                </div>
                <div className="choose-banner2">
                  <img
                    src={process.env.PUBLIC_URL + "/img/why-2.jpg"}
                    alt="why-choose images"
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/img/why-3.jpg"}
                    alt="why-choose images"
                  />
                </div>
                <div className="years">
                  <h5>20+</h5>
                  <span>Years</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Why Choose</span>
                  <h2>
                    Client Who Trust us both in Digital Marketing and Software
                    Development
                  </h2>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">320</span>
                    <sup>+</sup>
                    <h5>Project Completed</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">125</span>
                    <sup>+</sup>
                    <h5>Satisfied Clients</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">10</span>
                    <sup>+</sup>
                    <h5>Expert Teams</h5>
                  </div>
                </div>
                <p>
                  <div>
                    <strong>Client-Centric Approach:</strong> We believe in
                    forging long-lasting relationships with our clients. Your
                    success is our success.
                  </div>
                  <br />
                  <div>
                    <strong>Quality Assurance:</strong> We are committed to
                    delivering top-quality solutions that meet and exceed your
                    expectations.
                  </div>
                </p>
                <div className="buttons-group">
                  <span>Experienced Team</span>
                  <span>Affordable Pricing</span>
                  <span>Clean Code Develope</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyChooseUs;
