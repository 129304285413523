import React from "react";

function FaqWrap() {
  return (
    <>
      <section className="faqs-area sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="sidebar-tab">
                <div className="tabs">
                  <a href="#progress-tab" className="tab-link active">
                    Progress
                  </a>
                  <a href="#payment-tab" className="tab-link">
                    Payment
                  </a>
                  <a href="#support-tab" className="tab-link">
                    Support
                  </a>
                </div>
              </div>
              <div className="sidebar-tab">
                <div className="questions-form">
                  <h4>HAVE QUESTION?</h4>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    action="#"
                    method="post"
                  >
                    <input type="text" name="name" placeholder="Your Name :" />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email :"
                    />
                    <input
                      type="text"
                      name="number"
                      placeholder="Phone Number :"
                    />
                    <input type="text" name="subject" placeholder="Subject :" />
                    <textarea
                      name="message"
                      cols={30}
                      rows={10}
                      placeholder="Write Message :"
                      defaultValue={""}
                    />
                    <input type="submit" defaultValue="send now" />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="faqs-tabs">
                <div className="faqs" id="progress-tab">
                  <h4>Progress</h4>
                  <div className="accordion" id="accordionOne">
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading1">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          01. What types of software products do you offer?
                        </button>
                      </span>
                      <div
                        id="collapse1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                           Nebwin Tech offers a wide range of software services,
                          including e-commerce platforms,   LMS (Learning
                          Management Systems), POS systems, and custom software
                          solutions tailored to your needs.  
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading2">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="false"
                          aria-controls="collapse2"
                        >
                          02. Do you offer custom development or only pre-made
                          solutions?
                        </button>
                      </span>
                      <div
                        id="collapse2"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          We offer both! Whether you need a pre-made solution or
                          a fully customized product, we’re here to help.  
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse3"
                          aria-expanded="false"
                          aria-controls="collapse3"
                        >
                          03. What is the typical timeframe for a project?  
                        </button>
                      </span>
                      <div
                        id="collapse3"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading3"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          The time frame varies depending on the complexity of
                          the project, ranging from 1 to 6 months.  
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading4">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                        >
                          04. What kind of support do you offer post-purchase?
                        </button>
                      </span>
                      <div
                        id="collapse4"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading4"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul>
                            {" "}
                            <p>
                              We are committed to providing ongoing support to
                              help you maximize the value of our software. Our
                              support includes:  
                            </p>
                            <li>
                               - Technical Support: Assistance with technical
                              issues, usage guidance, and integration.  
                            </li>
                            <li>
                              - Product Updates:Regular updates for enhanced
                              functionality and security.  
                            </li>
                            <li>
                               - User Training:Comprehensive manuals, recorded
                              video tutorials, and in-person training (currently
                              available in Mandalay). 
                            </li>
                            <li>
                              - Dedicated Account Manager: For larger contracts
                              or custom solutions, we assign a single point of
                              contact.  
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading5">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse5"
                          aria-expanded="false"
                          aria-controls="collapse5"
                        >
                          05. How do updates and maintenance work for the
                          software?
                        </button>
                      </span>
                      <div
                        id="collapse5"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading5"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>
                              - For customized solutions, updates are made based
                              on client requests.  
                            </li>
                            <li>
                              - For pre-made solutions updates are provided as
                              needed, typically once a month.  
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading6">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse6"
                          aria-expanded="false"
                          aria-controls="collapse6"
                        >
                          06. Is training provided for the software?
                        </button>
                      </span>
                      <div
                        id="collapse6"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading6"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul>
                            <p>Yes! We offer:  </p>
                            <li> - Detailed user manuals.  </li>
                            <li> - Recorded video tutorials. </li>
                            <li>
                               - In-person training sessions (available in
                              Mandalay). {" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="faqs" id="payment-tab">
                  <h4>Payment</h4>
                  <div className="accordion" id="accordionTwo">
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading7">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse7"
                          aria-expanded="false"
                          aria-controls="collapse7"
                        >
                          01. How is pricing determined? Are there subscription
                          models?
                        </button>
                      </span>
                      <div
                        id="collapse7"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading7"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                             Pricing is tailored based on project requirements,
                            including complexity, features, and development
                            time.  
                          </p>
                          <ul>
                            <li>- Subscription Models: </li>
                            <li>
                               -Monthly Subscription: Ideal for short-term
                              needs.  
                            </li>
                            <li>
                              -Annual Subscription: Cost-effective option with
                              discounted pricing.
                            </li>
                            <li>
                               -One-Time Purchase: Permanent access with a
                              single payment for select products.  
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading8">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse8"
                          aria-expanded="false"
                          aria-controls="collapse8"
                        >
                          02. Do you offer payment plans or discounts for
                          long-term contracts?**  
                        </button>
                      </span>
                      <div
                        id="collapse8"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading8"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                           Yes, we offer flexible payment plans and
                          milestone-based schedules for larger projects.
                          Discounts are also available for annual or multi-year
                          contracts, including three-installment options for
                          long-term agreements.  
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading9">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse9"
                          aria-expanded="false"
                          aria-controls="collapse9"
                        >
                          03. What is your refund policy?
                        </button>
                      </span>
                      <div
                        id="collapse9"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading9"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body"></div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading10">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse10"
                          aria-expanded="false"
                          aria-controls="collapse10"
                        >
                          04. How do I start working with your agency?
                        </button>
                      </span>
                      <div
                        id="collapse10"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading10"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          You can reach out via phone, email, or submit a
                          proposal request through our website. 
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading11">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse11"
                          aria-expanded="false"
                          aria-controls="collapse11"
                        >
                          05. Can I get a consultation before committing to a
                          project?  
                        </button>
                      </span>
                      <div
                        id="collapse11"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading11"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Yes, we offer **free consultations** to identify your
                          needs and propose tailored solutions without
                          unnecessary extras.
                        </div>
                      </div>
                    </div>
                    {/* <div className="accordion-item">
                      <span className="accordion-header" id="heading12">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse12"
                          aria-expanded="false"
                          aria-controls="collapse12"
                        >
                          06. tristique suscipit mi vitae rutrum. Ut auctor
                          rutrum fringilla?
                        </button>
                      </span>
                      <div
                        id="collapse12"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading12"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Aptent taciti sociosqu ad litora torquent per conubia
                          nostra, per inceptos only Integer purus odiopl
                          himenaeos. Praesent nec neque at dolor venenatis
                          consectetur eu quis ex. the Donec lacinia andal
                          placerat felis non aliquam.Mauris nec justo vitae ante
                          auctor tol euismod sit amet non ipsum. sovo Praesent
                          commodo at massa eget suscipit. Utani vitae enim
                          velit.
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="faqs" id="support-tab">
                  <h4>Support</h4>
                  <div className="accordion" id="accordionThree">
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading13">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse13"
                          aria-expanded="false"
                          aria-controls="collapse13"
                        >
                          01. What should I do if I encounter a bug?  
                        </button>
                      </span>
                      <div
                        id="collapse13"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading13"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Our support team is available 7 days a week from 9 AM
                          to 6 PM. Simply reach out for assistance.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading14">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse14"
                          aria-expanded="false"
                          aria-controls="collapse14"
                        >
                          02. How can I contact support?
                        </button>
                      </span>
                      <div
                        id="collapse14"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading14"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul>You can reach us via:</ul>
                          <li>- Viber Contact: [Phone Number]</li>
                          <li>
                            - Website:
                            [support.nebwintech.com](http://support.nebwintech.com)
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading15">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse15"
                          aria-expanded="false"
                          aria-controls="collapse15"
                        >
                          03. How do you handle data privacy and security?
                        </button>
                      </span>
                      <div
                        id="collapse15"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading15"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul>
                            <p>We prioritize your data’s safety through:</p>
                            <li>
                              - Data Encryption: Ensures data is secure during
                              transit and storage.
                            </li>
                            <li>
                              - Access Control: Limits data access to authorized
                              personnel only.
                            </li>
                            <li>
                              - Regular Security Audits: Identifies and resolves
                              potential vulnerabilities.
                            </li>
                            <li>
                              - Compliance: Adheres to major data protection
                              regulations like GDPR and CCPA.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading16">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse16"
                          aria-expanded="false"
                          aria-controls="collapse16"
                        >
                          04. Is my data safe on your software platform?
                        </button>
                      </span>
                      <div
                        id="collapse16"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading16"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul>
                            <p>Absolutely. Your data is secured through:</p>
                            <li>- Advanced encryption methods.</li>
                            <li>- Secure, continuously monitored servers.</li>
                            <li>
                              - Routine audits and vulnerability assessments.
                            </li>
                            <li>- Regular backups to prevent data loss.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="accordion-item">
                      <span className="accordion-header" id="heading17">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse17"
                          aria-expanded="false"
                          aria-controls="collapse17"
                        >
                          05. What are the obstacles users are facing? What are
                          they trying to?
                        </button>
                      </span>
                      <div
                        id="collapse17"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading17"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Aptent taciti sociosqu ad litora torquent per conubia
                          nostra, per inceptos only Integer purus odiopl
                          himenaeos. Praesent nec neque at dolor venenatis
                          consectetur eu quis ex. the Donec lacinia andal
                          placerat felis non aliquam.Mauris nec justo vitae ante
                          auctor tol euismod sit amet non ipsum. sovo Praesent
                          commodo at massa eget suscipit. Utani vitae enim
                          velit.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <span className="accordion-header" id="heading18">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse18"
                          aria-expanded="false"
                          aria-controls="collapse18"
                        >
                          06. Why is this important? Why will users benefit from
                          solving this?
                        </button>
                      </span>
                      <div
                        id="collapse18"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading18"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          Aptent taciti sociosqu ad litora torquent per conubia
                          nostra, per inceptos only Integer purus odiopl
                          himenaeos. Praesent nec neque at dolor venenatis
                          consectetur eu quis ex. the Donec lacinia andal
                          placerat felis non aliquam.Mauris nec justo vitae ante
                          auctor tol euismod sit amet non ipsum. sovo Praesent
                          commodo at massa eget suscipit. Utani vitae enim
                          velit.
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqWrap;
