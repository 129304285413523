import React from "react";
import { Link } from "react-router-dom";

function HeroArea() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <section className="hero-area">
        <div className="ken-burns-slideshow">
          <img
            src={process.env.PUBLIC_URL + "/img/hero-banner.jpg"}
            alt="HeroBanner"
          />
        </div>
        <div className="verticale-social">
          <ul className="vertical-media">
            <li>
              <a href="https://web.facebook.com/officialnebwins">Facebook</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/nebwin-tech/">
                Linkedin
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@nebwintech?is_from_webapp=1&sender_device=pc">
                TikTot
              </a>
            </li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  <h1>
                    Creative &amp; Minimal
                    <span className="header_span">MARTECH AGENCY</span>
                  </h1>
                  <p>
                    Nebwin Tech is an innovative software company that
                    specializes in providing advanced website development,
                    E-Commerce platforms, applications, and software
                    solutions.Design the future with <br /> Nebwin Tech.
                  </p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link
                        to={`${process.env.PUBLIC_URL}/about`}
                        onClick={scrollTop}
                      >
                        About Us
                      </Link>
                    </div>
                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link
                        to={`${process.env.PUBLIC_URL}/project`}
                        onClick={scrollTop}
                      >
                        See Project
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroArea;
