import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 or-2 wow animate fadeIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Get To Know</span>
                <h2>About Us</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>
                  Lead the way to the future, Nebwin Tech is your partner in
                  innovation.
                </h3>
                <p>
                  Our team of highly skilled software and website developers
                  utilizes cutting-edge technology to create advanced solutions
                  tailored to your needs by delivering high-quality services at
                  reasonable prices and with top-notch quality.
                </p>
                <br />
                <p>
                  With Nebwin Tech, achieving success both locally and globally
                  is no longer a challenge. We are here to help you seamlessly
                  transition from local to global,
                  <br /> ensuring your business thrives in a competitive market.
                </p>
                <div className={`${"company-since"} ${props.layoutClass}`}>
                  <div
                    className="company-logo"
                    style={{
                      marginBottom: 10,
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/icons/logo.png"}
                      alt="logo-img"
                      style={{ maxWidth: 30 }}
                    />
                    <span style={{ width: 10 }}></span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h4 style={{ margin: 0, color: "#4496c8" }}>
                        Nebwin Tech
                      </h4>
                    </div>
                  </div>
                  <strong>#1</strong>
                  <h4>
                    Best MarTech Agency And Solutions Since 2017{" "}
                    <span>Since 2017.</span>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 or-1 wow animate fadeIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="about-right">
                <div className="banner-1">
                  <img
                    src={process.env.PUBLIC_URL + "/img/about-baner-1.jpg"}
                    alt="about-images"
                  />
                </div>
                <div className="banner-2">
                  <img
                    src={process.env.PUBLIC_URL + "/img/about-baner-2.jpg"}
                    alt="about-images"
                  />
                  <div className="banner2-inner">
                    <div className="play">
                      <div
                        className="video-popup"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fas fa-play" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="IUN664s7N-c"
          autoplay={1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default AboutArea;
