import React from "react";
import ServiceCard from "./ServiceCard";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>
                Curabitur sed facilisis erat. Vestibulum pharetra eros eget
                fringilla porttitor. on Duis a orci nunc. Suspendisse ac
                convallis sapien, quis commodo libero.
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={1}
                image="/img/icons/service-icon-1.png"
                title="Web Development"
                details="We build stunning, responsive websites that not only captivate your audience but also function seamlessly. Our websites are not just visually appealing; they are high-performance platforms designed to drive your business forward."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={2}
                image="/img/icons/service-icon-2.png"
                title="UI/UX Design"
                details="We craft intuitive and visually compelling user interfaces that provide seamless user experiences. Our approach ensures your digital products are user-centered, maximizing engagement and satisfaction while aligning with your brand's identity."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={3}
                image="/img/icons/service-icon-3.png"
                title="Mobile App Development"
                details="In a mobile-centric world, having a user-friendly, feature-rich mobile app is essential. We specialize in crafting engaging mobile apps for both iOS and Android, ensuring your business is just a tap away from your customers."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={4}
                image="/img/icons/service-icon-4.png"
                title="Graphic Design"
                details="Creative design is the heart and soul of your brand. Our design team is dedicated to turning your concepts into captivating visuals, from logos to user interfaces, ensuring your brand stands out from the crowd."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={5}
                image="/img/icons/service-icon-5.png"
                title="Digital Marketing"
                details="In the competitive digital landscape, visibility and engagement are paramount. Our expert digital marketing team uses the latest strategies and tools to enhance your online presence, boost brand recognition, and increase customer engagement."
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={6}
                image="/img/icons/service-icon-6.png"
                title="Video Animation"
                details="Bring your ideas to life with our stunning video animations. From promotional videos to explainer content, we use creative storytelling and high-quality visuals to communicate your message effectively and leave a lasting impact on your audience."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
